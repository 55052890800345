import React from "react";
import { graphql } from 'gatsby'
import RemoteSiteBody from '../sections/standardPage/RemoteSiteService'

const RemoveSiteServicesPage = (data) =>
    <RemoteSiteBody data={data}/>

export default RemoveSiteServicesPage;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 7}) {
            heading
            subheading
            pageName
            pageId
            descriptionParagraph {
                descriptionParagraph
            }
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
